<template>
	<div class="item-wrapper">
		<div class="item-top">
			<div class="preview" @click="goToSliderItem(item)">
				<img :src="imgDomain + item.img_url" class="img"/>
				<!-- <div v-if="tag" class="tag">
					<span>{{tag}}</span>
				</div> -->
				<transition name="fade">
					<span class="desc desc-added" v-if="addToCartChosenItem == item.id">Added</span>
				</transition>
				<div class="price desc">
					<span class="discount" v-if="item.old_price != '0.00'"><span class="currency">{{currency}}</span> {{item.old_price}}</span>
					<span><span class="currency"><b>{{currency}}</b></span> <b>{{item.price}}</b></span>
					<span class="percent" v-if="discountPercentage > 0">
						<b>-{{ discountPercentage }}%</b>
					</span>
				</div>
			</div>
		</div>
		<div class="item-bottom">
			<div class="price-container">
				<div class="desc" @click="goToSliderItem(item)">
					<b>{{item.title}}</b>
				</div>
			</div>
			<div class="desc tag">{{item.type}}</div>
			<div class="buttons">
				<div class="button" @click="goToSliderItem(item)">Buy now</div>
				<div class="button button-info" @click="$emit('addToCart',item, item.id)">
					<img src="./../img/cart.svg" class="img"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ProductCard',
	props: ['item', 'addToCartChosenItem', 'sliderIsClickble', 'tag', 'currency'],
	data: function() {
		return {
			imgDomain: ''
		}
	},
	computed: {
		discountPercentage() {
			if (parseFloat(this.item.old_price) > 0) {
				const oldPrice = parseFloat(this.item.old_price);
				const newPrice = parseFloat(this.item.price);
				const discount = ((oldPrice - newPrice) / oldPrice) * 100;
				return discount.toFixed(0);
			} else {
				return 0;
			}
		},
	},
	mounted() {
		this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
	},
	methods: {
		goToSliderItem(item) {
			this.$emit('goToProduct', item);
		},
	},
}
</script>
