<template>
  <div class="modal cart-modal">
    <div class="overlay overlay-invisible" @click="$parent.closeCart"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="cart-up">
                  <div class="cart-top">
                    <div class="desc">
                      <b>CART</b>
                    </div>
                    <img class="close" @click="$parent.closeCart" src="./../images/close.svg"/>
                  </div>
                  <div class="product-list">
                    <div class="desc" v-if="$parent.cartContents && !$parent.cartContents.length">Cart is empty</div>
                    <div class="item" v-for="(item, i) in $parent.cartContents" :key="i">
                      <div class="preview">
                        <img :src="$parent.imgDomain + item.item.img_url" class="img"/>
                      </div>
                      <div class="desc">
                        <b>{{item.item.title}}</b>
                      </div>
                      <div class="price desc">
                        <b>
                          <span class="currency">{{$parent.currency}}</span> {{item.item.price}} 
                        </b>
                      </div>
                      <div class="delete" @click="$parent.removeFromCart(item.item, item.item_id)">
                        <img class="img" src="./../images/bin.svg"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cart-bottom">
                  <div class="total-container">
                    <div class="desc">Total: {{ $parent.currency }} {{ $parent.totalSum }}</div>
                    <div class="desc">Balance: {{ $parent.currency }} {{ balance }}</div>
                  </div>
                  <button @click="buy" class="button red">BUY</button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                </transition>
                <transition name="slide">
                  <div v-if="successMessage" class="desc green">{{successMessage}}</div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Cart',
  props: [],
  components: {
  },
  data: function() {
    return {
      successMessage: '',
      balance: ''
    }
  },
  mounted() {
    this.getBalance();
  },
  methods: {
    getBalance() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.balance = res.data.balance;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    buy() {
      this.$http.post(process.env.VUE_APP_API + 'purchase/product')
      .then((res) => {
        let self = this;
        if (res.data.status == "OK") {
          this.$emit('setError', '');
          this.successMessage = 'Success';
          setTimeout(function() {
            self.$parent.closeCart();
            self.getBalance();
            self.$parent.getDepositHistory();
            self.$parent.getOrderHistory();
            self.$parent.getCartContents();
            self.$router.push({path: '/profile'});
          }, 1500)
          
        } else {
          this.$emit('setError', res.data.message)
        }
      })
      .catch((res) => {
        this.$emit('setError', res.response.data.message)
      })
    }
  }
}
</script>