<template>
  <div class="modal sign-modal reg-modal">
    <div class="overlay"></div>
    <div class="overlay overlay-invisible" @click="$emit('closeSignUp')"></div>
    <div class="wrapper">
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <img class="close" @click="$emit('closeSignUp')" src="./../images/close.svg"/>
            <div class='form-fields register-content'>
              <div class="form-fields-wrapper">
                <div class="title small">SIGN UP</div>
                <label>
                  <div class="desc">*First name</div>
                  <input type="text" placeholder="First name" v-model="name"/>
                </label>
                <label>
                  <div class="desc">*Last Name</div>
                  <input type="text" placeholder="Last Name" v-model="surname"/>
                </label>
                <label>
                  <div class="desc">*Phone</div>
                  <input type="text" @input="allowOnlyNumbers" placeholder="Phone" v-model="phone"/>
                </label>
                <label>
                  <div class="desc">*Email</div>
                  <input type="email" placeholder="Email" v-model="email"/>
                </label>
                <label>
                  <div class="desc">*Password</div>
                  <input type="password" placeholder="Password" v-model="pass"/>
                </label>
                <label>
                  <div class="desc">*Confirm Password</div>
                  <input type="password" placeholder="Confirm Password" v-model="passConfirm"/>
                </label>
                <div class="cta-container">
                  <div class="chekbox-container">
                    <label class="chekbox-label">
                      <div class="chekbox-label-wrapper">
                        <input type="checkbox" name="terms" v-model="terms"/>
                        <div class="checkbox"></div>
                        <span class="title">I agree with </span>
                        <a @click="$parent.goToPage('privacy')" class="title"> privacy policy</a>
                        <span class="title"> and </span>
                        <a @click="$parent.goToPage('terms')" class="title"> terms and conditions</a>
                      </div>
                    </label>
                  </div>
                  <button :class="['button red', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="submitRegister">
                    <span>Sign Up</span>
                  </button>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">{{$parent.error}}</div>
                </transition>
                <div class="modal-bottom">
                  <div class="desc">Already have an account?</div>
                  <div class="link switch-login" @click="openSignInModal()">Sign in here</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Registration',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      pass: '',
      name: '',
      surname: '',
      phone: '',
      passConfirm: '',
      terms: false
    }
  },
  mounted() {
    
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.email && this.pass && this.passConfirm
        && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    allowOnlyNumbers(){
      this.phone = this.phone.replace(/[^0-9.,]/g, '');

      // Split the input into integer and decimal parts
      const parts = this.phone.split(/[.,]/);
      const integerPart = parts[0];
      let decimalPart = parts[1];

      // Limit decimal part to two digits
      if (decimalPart !== undefined && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }

      // Concatenate the integer and decimal parts back
      this.phone = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;
    },
    closeSignUpModal() {
      this.$emit('closeSignIn')
    },
    openSignInModal() {
      this.$emit('openSignIn')
    },
    submitRegister() {
      let regData = {
        "name": this.name,
        "surname": this.surname,
        "email": this.email,
        "phone": this.phone,
        "password": this.pass,
        "passConfirm": this.passConfirm
      }
        this.$emit('registration', regData)
    }
  }
}
</script>