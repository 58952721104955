<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper home">
          <img src="./../img/line1.svg" class="line line1"/>
          <img src="./../img/line2.svg" class="line line2"/>
          <img src="./../img/line3.png" class="line line3"/>
          <div class="left">
            <img src="./../img/dota.png" class="img"/>
          </div>
          <div class="right">
            <div class="title big"><span>DOTABAZAAR</span></div>
            <div class="desc">Your Ultimate Dota 2 Skins Marketplace</div>
          </div>
        </div>
      </div>
      <div class="section dive-section">
        <div class="wrapper">
          <div class="left">
            <div class="desc big">
              DIVE INTO THE VAST WORLD OF DOTA 2 WITH OUR EXTENSIVE COLLECTION OF SKINS.
            </div>
            <div class="desc">
              From the fiery depths of the Underworld to the mystical realms of the Arcane, find the perfect skin to elevate your gaming experience.
            </div>
          </div>
          <div class="right">
            <router-link to="/product-list/all" class="button border">MARKETPLACE</router-link>
          </div>
        </div>
      </div>
      <div class="section step-section">
        <div class="wrapper">
          <div class="step">
            <div class="desc big">
              BROWSE:
            </div>
            <div class="desc">
              Explore a wide array of Dota 2 skins. Each listing provides detailed information, images, and pricing.
            </div>
          </div>
          <div class="step">
            <div class="desc big">
              CONNECT:
            </div>
            <div class="desc">
              Link your Steam account securely. We use Steam's API for a seamless integration.
            </div>
          </div>
          <div class="step">
            <div class="desc big">
              BUY:
            </div>
            <div class="desc">
              Choose your desired skins and buy with ease. Our platform ensures a safe and efficient buying experience.
            </div>
          </div>
        </div>
      </div>
      <div class="section contact-section">
        <div :class="['wrapper', {'active': contactIsActive}]">
          <div class="top" @click="contactIsActive = !contactIsActive">
            <div class="title">CONTACT US</div>
          </div>
          <div class="bottom">
            <div class="item">
              <div class="desc">
                Support:
              </div>
              <div class="title"><span>{{$parent.footerSupportEmail }}</span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="section feature-section">
        <div class="wrapper">
          <div class="title big">
            <span>WHY DOTABAZAAR?</span>
          </div>
          <div class="list">
            <div class="item">
              <div class="desc big">01</div>
              <img src="./../img/feature1.svg" class="img"/>
              <div class="desc big">EXTENSIVE COLLECTION</div>
              <div class="desc">
                Constantly updated inventory from the Steam marketplace.
              </div>
            </div>
            <div class="item">
              <div class="desc big">02</div>
              <img src="./../img/feature2.svg" class="img"/>
              <div class="desc big">SAFE AND SECURE</div>
              <div class="desc">
                We prioritize your security. Trade with confidence knowing your Steam assets are protected.
              </div>
            </div>
            <div class="item">
              <div class="desc big">03</div>
              <img src="./../img/feature3.svg" class="img"/>
              <div class="desc big">USER-FRIENDLY INTERFACE</div>
              <div class="desc">
                A seamless browsing and trading experience.
              </div>
            </div>
            <div class="item">
              <div class="desc big">04</div>
              <img src="./../img/feature4.svg" class="img"/>
              <div class="desc big">COMMUNITY-DRIVEN</div>
              <div class="desc">
                Connect with other Dota 2 enthusiasts.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section join-section">
        <img src="./../img/line4.svg" class="line1"/>
        <img src="./../img/line5.png" class="line2"/>
        <div class="wrapper">
          <div class="left">
            <div class="title">
              JOIN THE <span>DOTABAZAAR</span> COMMUNITY TODAY!
            </div>
            <router-link to="/product-list/all" class="button border">MARKETPLACE</router-link>
          </div>
          <div class="right">
            <img src="./../img/dota2.png" class="img"/>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Home',
  props: ['addToCartChosenItem', 'currency'],
  components: {
  },
  data: function() {
    return {
      imgDomain: '',
      list: [],
      contactIsActive: false
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
    getImagePath(item) {
      const cleanItem = item.replace(/\s+/g, '');
      return require(`@/assets/types/${cleanItem.toLowerCase()}.svg`);
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    addToCart(item, id) {
      this.$emit('addToCart', item, id)
    },
  }
}
</script>