var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"main home-page"},[_c('div',{staticClass:"main-wrapper"},[_vm._m(0),_c('div',{staticClass:"section dive-section"},[_c('div',{staticClass:"wrapper"},[_vm._m(1),_c('div',{staticClass:"right"},[_c('router-link',{staticClass:"button border",attrs:{"to":"/product-list/all"}},[_vm._v("MARKETPLACE")])],1)])]),_vm._m(2),_c('div',{staticClass:"section contact-section"},[_c('div',{class:['wrapper', {'active': _vm.contactIsActive}]},[_c('div',{staticClass:"top",on:{"click":function($event){_vm.contactIsActive = !_vm.contactIsActive}}},[_c('div',{staticClass:"title"},[_vm._v("CONTACT US")])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"desc"},[_vm._v(" Support: ")]),_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.$parent.footerSupportEmail))])])])])])]),_vm._m(3),_c('div',{staticClass:"section join-section"},[_c('img',{staticClass:"line1",attrs:{"src":require("./../img/line4.svg")}}),_c('img',{staticClass:"line2",attrs:{"src":require("./../img/line5.png")}}),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"left"},[_vm._m(4),_c('router-link',{staticClass:"button border",attrs:{"to":"/product-list/all"}},[_vm._v("MARKETPLACE")])],1),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section hero-section"},[_c('div',{staticClass:"wrapper home"},[_c('img',{staticClass:"line line1",attrs:{"src":require("./../img/line1.svg")}}),_c('img',{staticClass:"line line2",attrs:{"src":require("./../img/line2.svg")}}),_c('img',{staticClass:"line line3",attrs:{"src":require("./../img/line3.png")}}),_c('div',{staticClass:"left"},[_c('img',{staticClass:"img",attrs:{"src":require("./../img/dota.png")}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"title big"},[_c('span',[_vm._v("DOTABAZAAR")])]),_c('div',{staticClass:"desc"},[_vm._v("Your Ultimate Dota 2 Skins Marketplace")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"desc big"},[_vm._v(" DIVE INTO THE VAST WORLD OF DOTA 2 WITH OUR EXTENSIVE COLLECTION OF SKINS. ")]),_c('div',{staticClass:"desc"},[_vm._v(" From the fiery depths of the Underworld to the mystical realms of the Arcane, find the perfect skin to elevate your gaming experience. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section step-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"step"},[_c('div',{staticClass:"desc big"},[_vm._v(" BROWSE: ")]),_c('div',{staticClass:"desc"},[_vm._v(" Explore a wide array of Dota 2 skins. Each listing provides detailed information, images, and pricing. ")])]),_c('div',{staticClass:"step"},[_c('div',{staticClass:"desc big"},[_vm._v(" CONNECT: ")]),_c('div',{staticClass:"desc"},[_vm._v(" Link your Steam account securely. We use Steam's API for a seamless integration. ")])]),_c('div',{staticClass:"step"},[_c('div',{staticClass:"desc big"},[_vm._v(" BUY: ")]),_c('div',{staticClass:"desc"},[_vm._v(" Choose your desired skins and buy with ease. Our platform ensures a safe and efficient buying experience. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section feature-section"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"title big"},[_c('span',[_vm._v("WHY DOTABAZAAR?")])]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"desc big"},[_vm._v("01")]),_c('img',{staticClass:"img",attrs:{"src":require("./../img/feature1.svg")}}),_c('div',{staticClass:"desc big"},[_vm._v("EXTENSIVE COLLECTION")]),_c('div',{staticClass:"desc"},[_vm._v(" Constantly updated inventory from the Steam marketplace. ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"desc big"},[_vm._v("02")]),_c('img',{staticClass:"img",attrs:{"src":require("./../img/feature2.svg")}}),_c('div',{staticClass:"desc big"},[_vm._v("SAFE AND SECURE")]),_c('div',{staticClass:"desc"},[_vm._v(" We prioritize your security. Trade with confidence knowing your Steam assets are protected. ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"desc big"},[_vm._v("03")]),_c('img',{staticClass:"img",attrs:{"src":require("./../img/feature3.svg")}}),_c('div',{staticClass:"desc big"},[_vm._v("USER-FRIENDLY INTERFACE")]),_c('div',{staticClass:"desc"},[_vm._v(" A seamless browsing and trading experience. ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"desc big"},[_vm._v("04")]),_c('img',{staticClass:"img",attrs:{"src":require("./../img/feature4.svg")}}),_c('div',{staticClass:"desc big"},[_vm._v("COMMUNITY-DRIVEN")]),_c('div',{staticClass:"desc"},[_vm._v(" Connect with other Dota 2 enthusiasts. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_vm._v(" JOIN THE "),_c('span',[_vm._v("DOTABAZAAR")]),_vm._v(" COMMUNITY TODAY! ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{staticClass:"img",attrs:{"src":require("./../img/dota2.png")}})])
}]

export { render, staticRenderFns }