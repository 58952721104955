<template>
	<footer class="footer">
    <div class="footer-section">
      <div class="wrapper">
        <div class="left">
          <div class="info">
            <div class="desc">
              Support
            </div>
            <div class="desc link">
              {{$parent.footerSupportEmail }}
            </div>
          </div>
          <ul class="list">
            <li class="item" v-for="(item, i) in socials" :key="i">
              <a :href="item.link" target="_blank">
                <img :src="item.img" class="img"/>
              </a>
            </li>
          </ul>
        </div>
        <div class="center">
          <router-link to="/" class="logo">
            <img src="./../img/logo.svg" class="img"/>
          </router-link>
          <div class="text" v-if="$parent.footerRequisites">
            <div class="desc">{{$parent.footerRequisites}}</div>
          </div>
        </div>
        <div class="right">
          <div class="nav-container">
            <ul class="nav">
              <template v-for="item in $parent.textPageList">
                <li class="nav__item desc" :key="item.id" v-if="item.title">
                  <a @click="$parent.goToPage(item.id)">
                    <b>{{item.title}}</b>
                  </a>
                </li>
              </template>
              <li class="nav__item desc">
                  <router-link to="/product-list/all"><b>MARKETPLACE</b></router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-section">
      <div class="wrapper">
        <div class="copyright">
          <div class="desc" v-if="$parent.footerCopyright">Copyright {{ new Date().getFullYear() }}, {{$parent.footerCopyright}}</div>
        </div>
        <div class="payment-list">
          <div class="item mc">
            <img src="./../assets/mc_symbol.svg" class="img"/>
          </div>
          <div class="item">
            <img src="./../assets/visa.svg" class="img"/>
          </div>
          <div class="item">
            <img src="./../assets/3ds.png" class="img"/>
          </div>
          <div class="item apple-pay">
            <img src="./../assets/apple-pay.svg" class="img"/>
          </div>
          <div class="item google-pay">
            <img src="./../assets/google-pay-primary-logo-logo-svgrepo-com.svg" class="img"/>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
	name: 'Footer',
	props: [],
	data: function() {
		return {
      socials: ''
		}
	},
	methods: {
		
	},
	mounted() {
    this.$http.get(process.env.VUE_APP_API + 'social-links')
    .then((res) => {
      this.socials = res.data.payload
    })
    .catch(() => {
      
    })
  }
}
</script>
